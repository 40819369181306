import 'normalize.css'
import 'styles/index.scss'

import {gsap} from 'gsap';
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
gsap.registerPlugin(MorphSVGPlugin);


gsap
  .timeline({
    delay: 1,
  })
  .add(
    gsap.to(".start-1", {
      duration: 0.3,
      ease: "power4.out",
      morphSVG: { shape: ".end-1" }
    })
  )
  .add(
    gsap.to(".start-2", {
      duration: 0.3,
      ease: "power4.out",
      morphSVG: { shape: ".end-2" }
    }),
    "<"
  )
  .add(
    gsap.to(".start-3", {
      duration: 0.3,
      ease: "power4.out",
      morphSVG: { shape: ".end-3" }
    }),
    "<"
  )
  .add(
    gsap.to(".start-4", {
      duration: 0.3,
      ease: "power4.out",
      morphSVG: { shape: ".end-4" }
    }),
    "<"
  );
